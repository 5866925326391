<template>
  <div>
    <Breadcrumbs title="Classroom Logins Report" main="Reports"/>
    <div class="container-fluid">
      <b-row>
        <b-col cols="12">
          <div class="card">
            <div class="card-body">

              <b-row>
                <b-col cols="12" lg="6" xl="3">
                  <b-form-group label="From Date *">
                    <b-form-datepicker @input="clearResult()" reset-button close-button v-model="formSearch.from_date"></b-form-datepicker>
                  </b-form-group>
                </b-col>
                <b-col cols="12" lg="6" xl="3">
                  <b-form-group label="To Date *">
                    <b-form-datepicker :disabled="!formSearch.from_date" @input="clearResult()" reset-button close-button v-model="formSearch.to_date" class="btn-square border"></b-form-datepicker>
                  </b-form-group>
                </b-col>
                <b-col cols="12" lg="6" xl="3">
                  <b-form-group label="Username">
                    <b-form-input v-model="formSearch.username" class="btn-square border" placeholder="Username of the student"></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col cols="12" lg="3" class="p-t-30">
                  <b-button @click="fetchData" :disabled="!formSearch.to_date || loading" size="sm" class="btn btn-square text-uppercase btn-primary">Generate</b-button>
                </b-col>
              </b-row>

            </div>
          </div>

          <div class="card">
            <div class="card-body" id="print">
              <div class="text-center">

                <div class="text-center">
                  <h2 class="card-title">Classroom Login Report</h2>
                </div>

                <b-row>
                  <b-col cols="4" md="3" lg="2" class="text-left">Username</b-col>
                  <b-col cols="8" md="9" class="text-left">: {{ formSearch.username || "N/A" }}</b-col>
                </b-row>
                <b-row>
                  <b-col cols="4" md="3" lg="2" class="text-left">From Date</b-col>
                  <b-col cols="8" md="9" class="text-left">: {{formSearch.from_date || "N/A"}}</b-col>
                </b-row>
                <b-row>
                  <b-col cols="4" md="3" lg="2" class="text-left">To Date</b-col>
                  <b-col cols="8" md="9" class="text-left">: {{ formSearch.to_date || "N/A" }}</b-col>
                </b-row>


                <div class="table-responsive datatable-vue" v-show="!loading">
                  <b-table
                      ref="table"
                      show-empty
                      :items="tableRecords"
                      :fields="tableFields"
                      :filter="filter"
                      :current-page="1"
                      :per-page="perPage"
                  >
                    <template #cell(index)="row">{{ (1+row.index) }}</template>
                    <template #cell(created_at)="row">{{ moment(row.item.created_at).format('llll') }}</template>
                    <template #cell(success_loging)="row">
                      <strong class="text-uppercase text-success" v-if="row.item.success_loging">YES</strong>
                      <strong class="text-uppercase badge badge-danger text-white " v-else>NO</strong>
                    </template>
                  </b-table>
                </div>


                <b-row >
                  <b-col cols="4" class="text-left">
                    Records: <strong>{{ totalRows }}</strong>
                  </b-col>
                  <b-col cols="6" class="my-1">
                    <b-pagination
                        v-if="totalRows"
                        align="right"
                        class="my-0"
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                        pills
                    ></b-pagination>
                  </b-col>
                  <b-col cols="2" class="text-md-right">
                    <b-form-group label-cols="9" label="Per Page">
                      <b-form-select class="btn-square border text-center" v-model="perPage" :options="pageOptions"></b-form-select>
                    </b-form-group>
                  </b-col>
                </b-row>


              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import {checkUserPermissions} from "@/plugins/functions";
import CRUD_API from '@/services/crud'
import moment from "moment";
export default {
  name: "classroom_logins",
  computed: mapState({ user: state => JSON.parse(state.auth.user) }),
  watch: {
    currentPage() {
      this.fetchData()
    },
    perPage() {
      this.currentPage = 1
      this.fetchData()
    },
    filter() {
      this.currentPage = 1
      this.fetchData()
    },
  },
  data() {
    return {
      api_base: '/api/backend/reports/classroom-logins',
      filter: '',
      totalRows: 0,
      perPage: 10,
      currentPage: 1,
      pageOptions: [5,10,25,50,100],
      tableRecords: [],
      selected: [],
      loading: false,


      tableFields: [
        { key: 'index', label: '#', sortable: false, },
        { key: 'id', label: 'ID', sortable: true },
        { key: 'username', label: 'Username', sortable: false, class: 'text-left' },
        { key: 'password', label: 'Password', sortable: false, class: 'text-left' },
        { key: 'success_loging', label: 'Success Login', sortable: false, class: 'text-center' },
        { key: 'ip_addess', label: 'IP Address', sortable: false, class: 'text-right' },
        { key: 'created_at', label: 'Date/Time', sortable: true, class: 'text-center' }
      ],
      default_year: parseInt(moment().format('Y'), 10),
      default_month: parseInt(moment().format('M'), 10),
      formSearch: {
        from_date:'',
        to_date: '',
        username: '',
      },

    }
  },
  methods: {
    checkUserPermissions,
    clearResult(){ this.tableRecords = []; },
    handlePageChange(value) {
      //alert(value)
      this.perPage = value
      this.fetchData()
    },
    handlePageSizeChange(value) {
      this.currentPage = value
      this.fetchData()
    },
    // printReport(el) {
    //   const d = new Printd()
    //   d.print(document.getElementById(el), [`
    //       * { font-family: serif; font-size: 12px; },
    //       @page { margin: 2.5cm; }
    //       div.row > div { display: inline-block; }
    //       div.row { display: block; }
    //       h2 { font-size: 18px; text-align:center; }
    //    `])
    // },
    async fetchData() {
      this.tableRecords = []
      this.totalRows = 0
      this.loading = true
      await CRUD_API.index(this.api_base, {
        params: {
          username: this.formSearch.username,
          from_date: this.formSearch.from_date,
          to_date: this.formSearch.to_date,
          per_page: this.perPage,
          page: this.currentPage,
          filter: this.filter,
        }
      }).then(result => {
        this.tableRecords = result.data.data.data
        this.totalRows = result.data.data.total
      }).catch(error => {
        console.log(error)
        this.loading = false
        this.$toasted.error(error.message)
      }).finally(() => {
        this.loading = false
      })
    },
  }
}
</script>

<style scoped>

</style>
